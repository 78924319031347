import store from "../store/index";
export function clearOrders() {
  store.commit("newsetAddToCart", "");
  store.commit("setSubTotal", 0);
  store.commit("setTotalAmount", 0);
  store.dispatch("setMenuItems", "");
  store.dispatch("setOffer", "");
  store.dispatch("setDeliveryAddress", "");
  store.dispatch("setRestaurantId", "");
  store.commit("setVoucher", "");
}

export function emptyRestaurantData() {
  store.commit("setSearchResturants", "");
  store.commit("setDummySearchResturants", "");
  store.commit("setResturantsCategories", "");
}

function isvalidTimeForOrder(object, h1, m1, h2, m2) {
  var h = object.h;
  var m = object.m;
  return (h1 < h || (h1 == h && m1 <= m)) && (h < h2 || (h == h2 && m <= m2));
}

export function validTimeForOrder(restaurantObject, timeObject) {
  let currentDate = new Date();
  const day_name = currentDate.toLocaleDateString("default", {
    weekday: "long",
  });
  let timeSlots = restaurantObject.timeSlots;

  let filtered = timeSlots.find((t) => t.day === day_name);
  console.log("validTimeForOrder", timeSlots);

  if (filtered) {
    let openingTime = filtered.opening_time;
    let closingTime = filtered.closing_time;
    let opeingHour = openingTime.split(":")[0];
    let opeingminuts = openingTime.split(":")[1];
    let closingHour = closingTime.split(":")[0];
    let closingminuts = closingTime.split(":")[1];
    let validTimeForOrder = isvalidTimeForOrder(
      timeObject,
      opeingHour,
      opeingminuts,
      closingHour,
      closingminuts
    );

    if (!validTimeForOrder) {
      return false;
    }
    return true;
  } else {
    return false;
  }
}
