<template>
  <div id="restaurant-detail">
    <div class="checkout-banner">
      <img :src="'/images/header.jpeg'" alt="" class="w-100" />
    </div>
    <OrdersResposive />
    <div class="right-content">
      <div class="inner-detail">
        <div class="text-wrapper">{{ $t("ready_to_eat") }}</div>
        <div
          v-if="alert"
          role="alert"
          aria-live="polite"
          aria-atomic="true"
          class="alert alert-danger"
        >
          {{ alertText }}
        </div>
        <b-form-group :label="$t('order_type') + ' *'">
          <b-form-radio-group v-model="type">
            <b-form-radio value="1">{{ $t("delivery_address") }}</b-form-radio>
            <b-form-radio value="0">{{ $t("pickup") }}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group :label="$t('select_your_payment')">
          <b-form-radio-group v-model="payment_type">
            <b-form-radio value="0">{{ $t("cash") }}</b-form-radio>
            <b-form-radio value="1">{{ $t("online") }}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group
          :label="$t('select_your_payment_method')"
          v-if="payment_type == 1"
        >
          <b-form-radio-group v-model="payment_method">
            <b-form-radio value="stripe">{{ $t("stripe") }}</b-form-radio>
            <b-form-radio value="paypal">{{ $t("paypal") }}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <div class="sub-title" v-if="type == 1">
          {{ $t("your_order_delivery") }}
        </div>
        <div class="pt-4">
          <b-form @submit="onSubmit" @reset="onReset" ref="refFormName">
            <div v-if="type == 1">
              <div class="row">
                <b-form-group
                  class="col-md-8 col-12"
                  id="input-group-3"
                  :label="$t('delivery_address')"
                  label-for="input-3"
                >
                  <b-form-select
                    @change="onSelectAddress(userAddress)"
                    v-model="userAddress"
                    class="mb-3"
                    required
                  >
                    <option value="null" disabled>
                      {{ $t("select") }} {{ $t("delivery_address") }}
                    </option>
                    <option
                      v-bind:value="arm"
                      v-for="arm in this.$store.state.loginUserData.addresses"
                      v-bind:key="arm.postcode"
                    >
                      {{ bindAddress(arm) }}
                    </option>
                    <option value="new">{{ $t("add_new") }}</option>
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="row mb-4">
                <b-form-group
                  style="width: 100%; height: 39px"
                  class="col-md-8 col-12"
                  label="Delivery Address *"
                  v-if="this.userAddress == 'new'"
                >
                  <GmapAutocomplete
                    id="autocomplete"
                    ref="autocomplete"
                    style="width: 100%; height: 39px"
                    placeholder="Please type address"
                    @place_changed="googlePlaceApi"
                  >
                  </GmapAutocomplete>
                </b-form-group>
              </div>

              <div class="row">
                <b-form-group
                  :label="$t('postcode') + ' *'"
                  class="col-md-3 col-12"
                >
                  <b-form-input
                    :required="type == 1"
                    v-on:input="checkLatLngExist"
                    v-model="postcode"
                    :class="isReadOnly == 1 ? 'disable-input' : ''"
                    :disabled="isReadOnly == 1"
                    :placeholder="$t('select') + ' ' + $t('postcode')"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('s_name') + ' *'"
                  class="col-md-6 col-12"
                >
                  <b-form-input
                    v-model="street"
                    :placeholder="$t('enter') + ' ' + $t('s_name')"
                    :required="type == 1"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('city') + ' *'"
                  class="col-md-3 col-12"
                >
                  <b-form-input
                    :class="isReadOnly == 1 ? 'disable-input' : ''"
                    v-model="city"
                    :required="type == 1"
                    :disabled="isReadOnly == 1"
                    :placeholder="$t('enter') + ' ' + $t('city')"
                  >
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group :label="$t('floor')" class="col-md-6 col-12">
                  <b-form-input v-model="floor"> </b-form-input>
                </b-form-group>
                <b-form-group
                  :label="$t('h_no') + ' *'"
                  class="col-md-6 col-12"
                >
                  <b-form-input
                    :required="type == 1"
                    v-model="house_no"
                    :placeholder="$t('enter') + ' ' + $t('h_no')"
                  >
                  </b-form-input>
                </b-form-group>
              </div>

              <div class="row">
                <b-form-group
                  :label="$t('address_footer')"
                  class="col-md-6 col-12"
                >
                  <b-form-input v-model="address"> </b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row" v-if="this.$store.state.isAuthenticated">
              <b-form-group label="Voucher List" class="col-md-6 col-12">
                <b-form-select
                  @change="onSelectVoucher(voucher)"
                  v-model="voucher"
                >
                  <option value="null" disabled>
                    {{ $t("select") }} {{ $t("voucher") }}
                  </option>
                  <option
                    v-bind:value="voucher"
                    v-for="voucher in this.voucherList"
                    v-bind:key="voucher.unique_code"
                  >
                    {{ bindVoucher(voucher) }}
                  </option>
                  <option value="null">
                    {{ $t("remove") }} {{ $t("voucher") }}
                  </option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="sub-title">{{ $t("reac_you") }}</div>
            <div class="row pt-4">
              <b-form-group
                :label="$t('form_name') + ' *'"
                class="col-md-6 col-12"
              >
                <b-form-input v-model="username" type="text" required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('form_email') + ' *'"
                class="col-md-6 col-12"
              >
                <b-form-input
                  @keyup="isEmailValid"
                  v-model="email"
                  type="email"
                  required
                >
                </b-form-input>
              </b-form-group>
            </div>
            <div class="row">
              <b-form-group
                :label="$t('form_phone') + ' *'"
                class="col-md-6 col-12"
              >
                <b-form-input
                  maxlength="15"
                  v-model="phone_number"
                  type="text"
                  required
                >
                </b-form-input>
              </b-form-group>
              <b-form-group :label="$t('company_name')" class="col-md-6 col-12">
                <b-form-input type="text"> </b-form-input>
              </b-form-group>
            </div>
            <div class="row">
              <b-form-group label="Additional Comment" class="col-md-6 col-12">
                <b-form-textarea v-model="comment" placeholder="Enter Comment">
                </b-form-textarea>
              </b-form-group>
            </div>

            <div v-if="payment_type == 1 && disableButton">
              <div class="sub-title">{{ $t("like_to_pay") }}</div>
              <div
                class="row pt-4"
                v-if="payment_type == 1 && payment_method == 'stripe'"
              >
                <StripeCard
                  :stripetoken="stripetoken"
                  @stripeTokken="stripeTokken"
                />
              </div>
              <div
                v-if="payment_type == 1 && payment_method == 'paypal'"
                class="row pt-4"
              >
                <PayPal
                  @setLoaded="onApprove"
                  :payment_method="payment_method"
                />
              </div>
            </div>

            <div
              class="row pt-4"
              v-if="
                payment_type == 0 && this.$store.getters.AllorderList.length > 0
              "
            >
              <div class="col-md-6 col-12">
                <button type="submit" class="order-btn">
                  <i
                    style="color: rgb(123 217 182)"
                    class="fa fa-spinner fa-spin"
                    v-if="buttonloader"
                  ></i>
                  {{ $t("order_and_pay") }}
                </button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
    <Orders :showButton="showButton" />
  </div>
</template>

<script>
import Orders from "../components/Oders";
import { checkLatLng } from "../api/services";
import { orderPlace } from "../api/services";
import { clearOrders, validTimeForOrder } from "../global/helper";
import { refreshLogin } from "../api/services";
import { getUserVoucher } from "../api/services";
// import VueGoogleAutocomplete from "vue-google-autocomplete";

import StripeCard from "../components/StripeCard";
import PayPal from "../components/PayPal";
import OrdersResposive from "../components/OrdersResposive";

export default {
  name: "Restaurant-Detail",
  data() {
    return {
      count: 0,
      isReadOnly: 0,
      buttonloader: false,
      street: "",
      userAddress: null,
      address: "",
      city: "",
      floor: "",
      house_no: "",
      comment: "",
      token: null,
      number: 1,
      isHidden: true,
      isShow: true,
      showButton: false,
      postcode: this.$store.state.zipCodeSearch,
      stripetoken: false,
      paypal_payment: false,
      email: "",
      username: "",
      phone_number: "",
      alertText: "",
      alert: false,
      type: 1,
      payment_type: 0,
      additional_amount: 0,
      payment_method: "stripe",
      payment_option: false,
      stripe_token: "",
      menuitems: [],
      offers: [],
      eventClick: "",
      exist_in_area: true,
      loginUser: "",
      orderRestaurant: "",
      lat: "",
      lng: "",
      id: "",
      street_number: "",
      route: "",
      voucherList: [],
      voucher: null,
      voucher_discount: 0.0,
    };
  },
  components: {
    Orders,
    StripeCard,
    OrdersResposive,
    PayPal,
    // VueGoogleAutocomplete,
    // ModelListSelect,
  },

  watch: {
    stripe_token(val) {
      if (!val) return;
      this.check();
    },

    type(val) {
      let totlaAmount = this.$store.getters.sub_total;
      let restaurant = this.$store.getters.orderRestaurant;
      if (val == 1 && restaurant.type == "Pickup") {
        this.alertText = this.$t("does_not_provide");
        this.changeClasss = false;
        this.alert = true;
      }
      if (val == 0) {
        this.$store.commit("setTotalAmount", totlaAmount);
        this.$store.dispatch("setDeliveryAddress", "");
      }
      if (val == 1) {
        this.$store.dispatch("setDeliveryAddress", restaurant.addresses);
        this.$store.commit(
          "setTotalAmount",
          totlaAmount + restaurant.addresses.delivery_cost
        );
      }
    },
  },

  computed: {
    disableButton() {
      if (this.payment_type == 1 && this.type == 1) {
        return (
          this.street != "" &&
          this.postcode != "" &&
          this.city != "" &&
          this.username != "" &&
          this.email != "" &&
          this.exist_in_area &&
          this.phone_number != ""
        );
      } else if (this.type == 0) {
        return (
          this.email != "" && this.username != "" && this.phone_number != ""
        );
      } else {
        return true;
      }
    },
  },

  mounted() {
    this.$store.commit("setVoucher", "");
    let restaurant = this.$store.getters.orderRestaurant;
    if (restaurant) {
      if (restaurant.type === "Pickup") {
        this.type = 0;
      } else {
        this.type = 1;
      }
    }
    // clearOrders();
    this.loginUser = this.$store.state.loginUserData;
    this.email = this.loginUser ? this.loginUser.email : "";
    this.username = this.loginUser ? this.loginUser.username : "";
    this.phone_number = this.loginUser ? this.loginUser.phone_number : "";
    let data = JSON.stringify({
      type: "device",
      tab: "issued",
      user_id: this.loginUser.id,
    });
    getUserVoucher(data).then((responce) => {
      if (responce.code == 200) {
        this.voucherList = responce.data;
      }
    });
  },

  methods: {
    bindAddress(object) {
      let house_no = object.house_no ? object.house_no + ", " : "";
      let street = object.street ? object.street + ", " : "";
      let floor = object.floor ? object.floor + ", " : "";
      let postcode = object.postcode ? object.postcode + ", " : "";
      let city = object.city ? object.city : "";
      let completeAddress = street + house_no + floor + postcode + city;
      return completeAddress;
    },
    bindVoucher(object) {
      let unique_code = object.unique_code ? object.unique_code + ", " : "";
      let type = object.voucher ? object.voucher.type : "";
      let voucherObject = unique_code + type;
      return voucherObject;
    },
    isEmailValid: function isEmailValid() {
      var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      var responce = regex.test(this.email);
      if (responce) {
        this.alert = false;
      } else {
        this.alertText = this.$t("validation_email");
        this.changeClasss = false;
        this.alert = true;
      }
      return responce;
    },
    googlePlaceApi: function (addressData) {
      var places = addressData;
      this.address = places.formatted_address;
      this.lat = places.geometry.location.lat();
      this.lng = places.geometry.location.lng();
      for (var i = 0; i < places.address_components.length; i++) {
        for (var j = 0; j < places.address_components[i].types.length; j++) {
          if (places.address_components[i].types[j] == "postal_code") {
            this.postcode = places.address_components[i].long_name;
            console.log(this.postcode);
          }
          if (places.address_components[i].types[j] == "locality") {
            this.city = places.address_components[i].long_name;
          }
          if (places.address_components[i].types[j] == "street_number") {
            this.street_number = places.address_components[i].long_name;
          }
          if (places.address_components[i].types[j] == "route") {
            this.route = places.address_components[i].long_name;
          }
        }
      }
      this.street = this.route + "" + this.street_number;
      this.isReadOnly = 1;
      console.log(this.postcode);
      this.checkLatLngExist();
    },
    onSelectVoucher: function onSelectVoucher() {
      if (this.voucher == "null" || this.voucher == null) {
        this.voucher = null;
        this.$store.commit("setVoucher", "");
        return;
      } else {
        this.$store.commit("setVoucher", this.voucher);
      }
      this.voucherCalculation();
    },

    onSelectAddress: function onSelectAddress() {
      if (this.userAddress != null) {
        if (this.userAddress == "new") {
          this.isReadOnly = 0;
          this.emptyFiled();
        } else {
          let data = {
            postcode: this.userAddress.postcode,
          };
          checkLatLng(data).then((responce) => {
            if (responce.code == 200) {
              let filtered = this.loginUser.addresses.find(
                (t) => t.id == this.userAddress.id
              );
              if (filtered) {
                this.isReadOnly = 1;
                this.id = filtered.id;
                this.postcode = filtered.postcode;
                this.street = filtered.street;
                this.city = filtered.city;
                this.address = filtered.address;
                this.house_no = filtered.house_no;
                this.floor = filtered.floor;
                this.lat = filtered.lat;
                this.lng = filtered.lng;
              }
              this.exist_in_area = true;
              let deliverData = responce.data[0].object_address;

              let deliveryAddress = {
                id: this.id,
                postcode: this.postcode,
                city: this.city,
                house_no: this.house_no,
                lat: this.lat,
                address: this.address,
                lng: this.lng,
                street: this.street,
                floor: this.floor,
                delivery_cost: deliverData.delivery_cost,
                minimum_order_amount: deliverData.minimum_order_amount,
              };

              let deduct_cost = this.$store.getters.delivery_address
                ? this.$store.getters.delivery_address.delivery_cost
                : 0;
              let add_amount =
                this.$store.getters.total_amount +
                deliveryAddress.delivery_cost;
              this.$store.commit("setTotalAmount", add_amount - deduct_cost);
              this.$store.dispatch("setDeliveryAddress", deliveryAddress);
            } else {
              this.$swal({
                text: responce.message,
                type: "warning",
                allowOutsideClick: false,
                closeOnClickOutside: false,
                allowEscapeKey: false,
              });
              this.exist_in_area = false;
            }
          });
        }
      }
    },
    checkLatLngExist() {
      let data = {
        postcode: this.postcode,
      };
      checkLatLng(data).then((responce) => {
        if (responce.code == 200) {
          this.exist_in_area = true;
          let deliverData = responce.data[0].object_address;
          let deliveryAddress = {
            id: this.id,
            postcode: this.postcode,
            city: this.city,
            house_no: this.house_no,
            street: this.street,
            address: this.address,
            floor: this.floor,
            lat: this.lat,
            lng: this.lng,
            delivery_cost: deliverData.delivery_cost,
            minimum_order_amount: deliverData.minimum_order_amount,
          };

          let deduct_cost = this.$store.getters.delivery_address
            ? this.$store.getters.delivery_address.delivery_cost
            : 0;
          let add_amount =
            this.$store.getters.total_amount + deliveryAddress.delivery_cost;
          this.$store.commit("setTotalAmount", add_amount - deduct_cost);
          this.$store.dispatch("setDeliveryAddress", deliveryAddress);
        } else {
          this.$swal({
            text: responce.message,
            type: "warning",
            allowOutsideClick: false,
            closeOnClickOutside: false,
            allowEscapeKey: false,
          });
          this.exist_in_area = false;
        }
      });
    },
    check() {
      try {
        if (this.alert) return;
        if (this.payment_type == 1 && this.payment_method == "stripe") {
          if (!this.stripe_token) return;
        }
        this.menuitems = this.$store.getters.menuItems
          ? this.$store.getters.menuItems
          : [];
        this.offers = this.$store.getters.offersList
          ? this.$store.getters.offersList
          : [];
        if (this.postcode && this.type == 1) {
          this.buttonloader = true;
          if (this.exist_in_area) {
            this.orderPlaceApi(this.setApiData());
          } else {
            this.$swal({
              text: this.$t("not_give_delivery"),
              type: "warning",
              allowOutsideClick: false,
              closeOnClickOutside: false,
              allowEscapeKey: false,
            });
            this.buttonloader = false;
          }
        } else {
          this.buttonloader = true;
          this.orderPlaceApi(this.setApiData());
        }
      } catch (err) {
        if (!err.response) {
          console.log(err);
          alert("Please Check You Internet Connection");
        }
      }
    },

    onApprove(data) {
      if (data.status == "COMPLETED") {
        this.paypal_payment = true;
        this.check();
      }
    },

    // checkVoucherAmountValidation() {
    //   if (
    //     this.voucher.voucher.type == "Order discount" &&
    //     this.removeDeliveryCost() < this.voucher.minimum_order_amount
    //   ) {
    //     this.alert = true;
    //     this.alertText =
    //       this.$t("voucher_validation") +
    //       this.voucher.minimum_order_amount +
    //       this.$t("excluding_delivery_charges");
    //     return false;
    //   } else {
    //     this.alert = false;
    //   }
    // },
    onSubmit(evt) {
      if (!this.exist_in_area) {
        this.alert = true;
        this.alertText =
          "This restaurant doesn’t provide delivery services in your selected area";

        evt.preventDefault();
        return false;
      }

      evt.preventDefault();
      if (this.alert) {
        return;
      }
      if (this.payment_type == 1 && this.payment_method == "stripe") {
        this.stripetoken = true;
      }

      if (this.postcode == this.$store.state.zipCodeSearch) {
        let delivery_cost = this.$store.getters.delivery_address.delivery_cost;
        let lat = this.$store.getters.delivery_address.lat;
        let lng = this.$store.getters.delivery_address.lng;
        let minimum_order_amount =
          this.$store.getters.delivery_address.minimum_order_amount;

        // this.$store.dispatch("setDeliveryAddress", "");
        let delivery = {
          id: this.id,
          postcode: this.postcode,
          city: this.city,
          house_no: this.house_no,
          street: this.street,
          floor: this.floor,
          address: this.address,
          delivery_cost: delivery_cost,
          lat: lat,
          lng: lng,
          minimum_order_amount: minimum_order_amount,
        };

        let total_price =
          this.$store.getters.total_amount - delivery.delivery_cost;
        this.additional_amount = minimum_order_amount - total_price;
        if (total_price < minimum_order_amount && this.type == 1) {
          this.$swal({
            text:
              this.$t("your_order_amount") +
              " " +
              total_price.toFixed(2) +
              "€  " +
              this.$t("is_less_than") +
              minimum_order_amount.toFixed(2) +
              "€.  " +
              this.$t("do_you_want") +
              minimum_order_amount.toFixed(2) +
              "€.  " +
              this.$t("you_have_to_pay") +
              this.additional_amount.toFixed(2) +
              "€ " +
              this.$t("extra_other_than"),
            allowOutsideClick: false,
            showCancelButton: true,
            closeOnClickOutside: false,
            allowEscapeKey: false,
            cancelButtonText: "No",
            confirmButtonText: "Yes",
          }).then((result) => {
            if (result.value) {
              this.check();
            } else {
              this.additional_amount = 0;
              return false;
            }
          });
        } else {
          this.check();
        }
      } else {
        let delivery_cost = this.$store.getters.delivery_address.delivery_cost;
        let lat = this.$store.getters.delivery_address.lat;
        let lng = this.$store.getters.delivery_address.lng;
        let minimum_order_amount =
          this.$store.getters.delivery_address.minimum_order_amount;

        this.$store.dispatch("setDeliveryAddress", "");
        let delivery = {
          id: this.id,
          postcode: this.postcode,
          city: this.city,
          house_no: this.house_no,
          street: this.street,
          address: this.address,
          floor: this.floor,
          delivery_cost: delivery_cost,
          lat: lat,
          lng: lng,
          minimum_order_amount: minimum_order_amount,
        };
        this.$store.dispatch("setDeliveryAddress", delivery);
        let total_price =
          this.$store.getters.total_amount - delivery.delivery_cost;
        this.additional_amount = minimum_order_amount - total_price;
        if (total_price < minimum_order_amount && this.type == 1) {
          this.$swal({
            text:
              this.$t("your_order_amount") +
              " " +
              total_price.toFixed(2) +
              "€  " +
              this.$t("is_less_than") +
              minimum_order_amount.toFixed(2) +
              "€.  " +
              this.$t("do_you_want") +
              minimum_order_amount.toFixed(2) +
              "€.  " +
              this.$t("you_have_to_pay") +
              this.additional_amount.toFixed(2) +
              "€ " +
              this.$t("extra_other_than"),
            allowOutsideClick: false,
            showCancelButton: true,
            closeOnClickOutside: false,
            allowEscapeKey: false,
            cancelButtonText: "No",
            confirmButtonText: "Yes",
          }).then((result) => {
            if (result.value) {
              this.check();
            } else {
              this.additional_amount = 0;
              return false;
            }
          });
        } else {
          this.check();
        }
      }
    },

    setApiData() {
      let deliveryCost = this.$store.getters.delivery_address.delivery_cost
        ? this.$store.getters.delivery_address.delivery_cost
        : 0;
      let data = JSON.stringify({
        deliveryAddress: JSON.stringify(this.$store.getters.delivery_address),
        menuitems: JSON.stringify(this.menuitems),
        offers: JSON.stringify(this.offers),
        order_type: this.type,
        payment_status: this.payment_type == 1 ? 1 : 0,
        payment_type: this.payment_type,
        email: this.email,
        username: this.username,
        phone_number: this.phone_number,
        stripe_token: this.stripe_token,
        voucher_id: this.voucher ? this.voucher.unique_code : "",
        platfrom: "web",
        voucher_discount: this.voucher_discount,
        totalprice: this.$store.getters.total_amount - deliveryCost,
        restaurant_id: this.$store.getters.restaurantId,
        payment_method: this.payment_method,
        comment: this.comment,
      });
      return data;
    },

    emptyFiled() {
      this.postcode = "";
      this.id = "";
      this.city = "";
      this.floor = "";
      this.address = "";
      this.house_no = "";
      this.street = "";
    },

    voucherCalculation() {
      if (this.voucher) {
        this.voucher_discount = this.voucher.voucher_discount;
      }
    },

    removeDeliveryCost() {
      var totalAmount = 0;
      let deliveryCost = this.$store.getters.delivery_address.delivery_cost
        ? this.$store.getters.delivery_address.delivery_cost
        : 0;
      totalAmount =
        parseFloat(this.$store.getters.total_amount) - parseFloat(deliveryCost);
      return totalAmount;
    },

    stripeTokken(data) {
      if (data.error) {
        this.alert = true;
        this.alertText = "Please enter valid card";
      } else {
        this.stripe_token = data.token.id;
        return;
      }
      this.stripetoken = false;
    },

    onReset(evt) {
      evt.preventDefault();
    },
    customLabel(item) {
      return `${item.postcode} `;
    },
    settimeObject(dateFormat) {
      let date = dateFormat;
      let timeobject = {
        h: date.getHours(),
        m: date.getMinutes(),
      };
      return timeobject;
    },

    orderPlaceApi: function (apiObject) {
      let restaurant = this.$store.getters.allSearchResturants.data.find(
        (x) => x.id == this.$store.getters.restaurantId
      );
      let restaurantName = restaurant.name;
      if (!validTimeForOrder(restaurant, this.settimeObject(new Date()))) {
        this.buttonloader = false;
        this.$swal({
          text:
            "Restaurant " +
            restaurantName +
            " is closed and order can not be placed at this time.",
          allowOutsideClick: false,
          closeOnClickOutside: false,
          allowEscapeKey: false,
        });
        return false;
      }
      if (this.menuitems.length == 0 && this.offers.length == 0) {
        this.alert = true;
        this.alertText = "Can'not place empty order";
        return false;
      }
      orderPlace(apiObject).then((responce) => {
        if (responce.code == 200) {
          refreshLogin().then((responce) => {
            if (responce.code == 200) {
              this.$store.commit("updateLoginUser", responce.data);
              localStorage.setItem(
                "currentUser",
                JSON.stringify(responce.data)
              );
              localStorage.setItem(
                "token",
                JSON.stringify(responce.data.token)
              );
            } else {
              console.log("==>", responce);
            }
          });
          this.buttonloader = false;
          clearOrders();
          this.$swal({
            text: responce.message,
            allowOutsideClick: false,
            closeOnClickOutside: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.value) {
              this.$router.push({ path: "/" });
            }
          });
        } else {
          this.alert = true;
          this.alertText = responce.message;
          this.buttonloader = false;
        }
      });
    },
  },
};
</script>
